<template>
  <div class="picture-page">
    <el-upload v-for="(pic, $index) of picList" :key="$index" class="avatar-uploader" :auto-upload="false" :on-change="beforeUpload" :show-file-list="false" action="#">
      <template v-if="pic">
        <img :src="`${$host}:7001/public/${pic.content}`" class="avatar">
        <i @click="handleDelete($event, pic.id)" class="el-icon-delete" style="display: block;background: #f18585;color: #fff;"></i>
      </template>
      <template v-else>
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </template>
    </el-upload>
    <el-upload class="avatar-uploader" :auto-upload="false" :on-change="beforeUpload" :show-file-list="false" action="#">
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        picList: []
      }
    },
    mounted() {
      this.getPicList()
    },
    methods: {
      beforeUpload(event) {
        const { raw, status } = event
        if (status === 'ready') {
          const isJPG = raw.type === 'image/jpeg';
          const isLt2M = raw.size / 1024 / 1024 < 10;
      
          if (!isJPG) {
            this.$notify.error('上传头像图片只能是 JPG 格式!');
            return
          }
          if (!isLt2M) {
            this.$notify.error('上传头像图片大小不能超过 10MB!');
            return
          }
          if (isJPG || isLt2M) {
            const formData = new FormData()
            formData.append('content', raw)
            axios.post('/api/picture', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(data => {
              if (data.data === 'success') {
                this.$notify.success('上传成功')
                this.getPicList()
              } else {
                this.$notify.error('上传失败')
              }
            })
          }
        }
      },
      handleDelete(event, id) {
        event.stopPropagation()
        axios.delete(`/api/picture/${id}`).then(data => {
          if (data.data === 'success') {
            this.$notify.success('删除成功')
            this.getPicList()
          } else {
            this.$notify.error('删除失败')
          }
        })
      },
      getPicList() {
        axios.get('/api/picture').then(data => {
          this.picList = data.data
        })
      },
      bufferToImage(buffer) {
        let bytes = new Uint8Array(buffer.data)
        let data = ''
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
          data += String.fromCharCode(bytes[i])
        }
        return data
      },
    }
  }
</script>

<style lang="less">
  .picture-page {
    display: flex;
    .avatar-uploader .el-upload {
      margin: 0 4px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .mini-pic {
      width: 100px;
      height: 40px;
    }
  }
</style>
